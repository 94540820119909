import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../../context/AuthContext'; // Adjust the path as needed
import { API_BASE_URL } from '../../../config/start.mjs'; // Adjust the path as needed
import { useTranslation } from 'react-i18next';
import './SalesHistory.css'; // Add custom styling if needed

const SalesHistory = () => {
  const [salesHistory, setSalesHistory] = useState([]);
  const { user } = useContext(AuthContext); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchSalesHistory = async () => {
      try {
        if (user && user._id) {
          const response = await axios.get(`${API_BASE_URL}/api/partner/sellhistory/${user._id}`);
          setSalesHistory(response.data);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSalesHistory();
  }, [user]);

  if (loading) return <p>{t('loading')}</p>;
  if (error) return <p>{t('error', { message: error })}</p>;

  return (
    <div className="sales-history">
      <h1>{t('salesHistoryTitle')}</h1>
      <p>{t('salesHistoryDescription')}</p>
      {salesHistory.length > 0 ? (
        <table className="sales-history-table">
          <thead>
            <tr>
              <th>{t('productName')}</th>
              <th>{t('price')}</th>
              <th>{t('date')}</th>
              <th>{t('status')}</th>
            </tr>
          </thead>
          <tbody>
            {salesHistory.reverse().map((sale, index) => (
              <tr key={index}>
                <td>{sale.productName}</td>
                <td>{sale.price.toFixed(2)}€</td>
                <td>{new Date(sale.date).toLocaleDateString()}</td>
                <td>{t(`sellStatus.${sale.status}`)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>{t('noSalesHistory')}</p>
      )}
    </div>
  );
};

export default SalesHistory;
