import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-modal';
import { API_BASE_URL } from '../../config/start.mjs';
import './ViewProduct.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../../context/AuthContext';
import { useWebSocket } from '../../context/WebSocketContext';
import { useTranslation } from 'react-i18next';
import ProductImageSlider from "../../components/ProductImageSlider/ProductImageSlider";

const SHIPPING_FEES_THRESHOLD = 100; // Define your threshold value here

const ViewProduct = () => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const { isAuthenticated, setUser, user } = useContext(AuthContext);
  const { socket, ready, registerMessageHandler, unregisterMessageHandler } = useWebSocket();
  const { t } = useTranslation();
  const [pulseSpeed, setPulseSpeed] = useState('1s'); // Default pulse speed


  const [timeRemaining, setTimeRemaining] = useState('');
  const [userBid, setUserBid] = useState('');
  const [biddingError, setBiddingError] = useState(null);
  const [favoriteError, setFavoriteError] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isAuctionOver, setIsAuctionOver] = useState(false);
  const [delivery, setDelivery] = useState(null);
  const [shippingFees, setShippingFees] = useState(0);

  useEffect(() => {  
    if (ready && socket) {
      const handleProductSet = (data) => {
        console.log("Received productSet:", data);
        setProduct(data);
      };

      const handleProductUpdate = (data) => {
        console.log("Received productUpdate:", data);
        setProduct((prevProduct) => ({
          ...prevProduct,
          bid: {
            ...prevProduct.bid,
            amount: data.highestBid,
            bidderId: data.highestBidder
          }
        }));
      };

      registerMessageHandler('productSet', handleProductSet);
      registerMessageHandler('productUpdate', handleProductUpdate);

      socket.send(JSON.stringify({
        type: 'subscribe',
        data: { productId }  
      }));

      return () => {
        unregisterMessageHandler('productSet');
        unregisterMessageHandler('productUpdate');
      };
    }
  }, [productId, socket, registerMessageHandler, unregisterMessageHandler, ready]);

  // Calculate time remaining
  useEffect(() => {
    if (!product || !product.auctionEndDate) return;
    const auctionEndDate = new Date(product.auctionEndDate);
    if (isNaN(auctionEndDate.getTime())) {
      console.error('Invalid auction date:', product.auctionEndDate);
      return;
    }
    const updateTimer = () => {
      const now = new Date();
      const timeDiff = auctionEndDate - now;
      if (timeDiff <= 0) {
        setTimeRemaining(t('auctionEnded'));
        setIsAuctionOver(true);
        return;
      }
      
      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
      
      const totalMinutes = Math.floor(timeDiff / (1000 * 60));

      if (totalMinutes <= 60 && totalMinutes > 5){
        setPulseSpeed('0.5s')
      }
      if (totalMinutes <= 5){
        setPulseSpeed('0.25s')
      }

      setTimeRemaining(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    };

    updateTimer();
    const timerInterval = setInterval(updateTimer, 1000);

    return () => clearInterval(timerInterval);
  }, [product, t]);

  useEffect(() => {
    const calculateShipping = async (address, weight) => {
      try {
        const response = await axios.post(`${API_BASE_URL}/api/colissimo/calculate-shipping`, { address, weight });

        if (response.status === 200) {
          const { price } = response.data;
          console.log('Shipping Price:', price);
          return price;
        }
      } catch (error) {
        console.error('Error calculating shipping:', error);
        return 0; // Return 0 in case of error
      }
    };

    const calculateAndSetShippingFees = async () => {
      if (user.shippingAddress && product?.weight) {
        const fees = await calculateShipping(user.shippingAddress, product.weight);
        setShippingFees(fees);
      }
    };

    if (product && product.weight && user?.shippingAddress) {
      calculateAndSetShippingFees();
    }
  }, [product, user?.shippingAddress]);
  

  const handleFavoriteClick = async () => {
    try {
      if (isAuthenticated) {
        const response = await axios.post(`${API_BASE_URL}/api/user/${user._id}/favorite`, { productId });
        const updatedFavoriteProducts = response.data.favoriteProducts;
        setUser((prevUser) => ({
          ...prevUser,
          favoriteProducts: updatedFavoriteProducts
        }));
        setFavoriteError(null); 
        console.log("favorite product set/unset")
      } else {
        setFavoriteError(t('favoriteErrors.notAuthenticated'));
      }
    } catch (error) {
      console.error('Error adding to favorites:', error);
      setFavoriteError(t('favoriteErrors.errorAddingFavorite'));
    }
  };

  const handleBidChange = (e) => {
    const value = e?.target?.value || '';
    
    // Check if the value is a decimal
    if (value && value.includes('.')) {
      setBiddingError(t('biddingErrors.roundNumberRequired'));
      return; // Don't update userBid if there's an error
    }
  
    // Clear the error if the value is a valid whole number
    setBiddingError('');
  
    // Update userBid only with valid whole numbers
    setUserBid(value);
  };

  const handleBidSubmit = () => {
    if (!user?.shippingAddress) {
      setBiddingError(
        <>
          {t('needToSetShippingAddress')}{' '}
          <button onClick={() => navigate('/settings')}>{t('setShippingAddress')}</button>
        </>
      );
      return;
    }

    const currentBidAmountAndFees = user?._id === product?.bid?.bidderId ? product.bid.amount + product.bid.shippingFees : 0;
    const effectiveWalletAmount = user.wallet.amount + currentBidAmountAndFees;

    if(parseFloat(userBid) >= SHIPPING_FEES_THRESHOLD){
      if (userBid > effectiveWalletAmount) {
        setBiddingError(t('biddingErrors.insufficientBalance'));
        return;
      }
    } else {
      if (parseFloat(userBid) + shippingFees > effectiveWalletAmount) {
        setBiddingError(t('biddingErrors.insufficientBalance'));
        return;
      }
    }
    if (timeRemaining === t('auctionEnded')) {
      setBiddingError(t('biddingErrors.auctionEnded'));
      return;
    }
    if (userBid <= (product.bid ? product.bid.amount : product.startingPrice)) {
      setBiddingError(t('biddingErrors.bidTooLow'));
      return;
    }

    setIsConfirmModalOpen(true);
  };

  const handleConfirmBid = async () => {
    setIsConfirmModalOpen(false);
    try {     
      const body = {
        productId: productId,
        userBid: parseFloat(userBid),
        shippingAddress: user?.shippingAddress,
        shippingFees: shippingFees
      };
      if(parseFloat(userBid) >= SHIPPING_FEES_THRESHOLD){body.shippingFees = 0}

      const response = await axios.post(`${API_BASE_URL}/api/bids/place`, body);

      if (response.status !== 200) {
        throw new Error(`Server responded with status code ${response.status}`);
      }

      const data = response.data;
      setProduct(data.updatedProduct);
      setUser(data.updatedUser);
      setBiddingError(null);
      setIsSuccessModalOpen(true);
    } catch (error) {
      setBiddingError(t('biddingErrors.errorSubmittingBid'));
    }
  };

  const isFavorite = user?.favoriteProducts?.some((fav) => fav.productId === productId);
  const heartColor = isFavorite ? 'red' : 'grey';

  const userWonAuction = isAuthenticated && timeRemaining === t('auctionEnded') && user?._id === product?.bid?.bidderId;
  const userIsHighestBidder = isAuthenticated && timeRemaining !== t('auctionEnded') && user?._id === product?.bid?.bidderId;

  useEffect(() => {
    const fetchDeliveryDetails = async () => {
      try {
        console.log("SENT")
        const response = await axios.get(`${API_BASE_URL}/api/products/deliveries/${productId}`);
        setDelivery(response.data);
      } catch (error) {
        console.error('Error fetching delivery details:', error);
      }
    };

    if (userWonAuction) {
      fetchDeliveryDetails();
    }
  }, [userWonAuction, productId]);

  if (!product) {
    return <div>{t('loading')}</div>;
  }

  return (
    <div className="background">
      <div className='back-button-container'>
        <button className="back-button" onClick={() => navigate(-1)}>{t('back')}</button>
      </div>
      <div className="product-image">
        <ProductImageSlider images={product.images} name={product.name} />
      </div>
      <div className="product-details">
        <h1 className="product-title">
          {product.name}
          <FontAwesomeIcon 
            icon={faHeart} 
            className="favorite-icon" 
            onClick={handleFavoriteClick} 
            style={{ color: heartColor }}
          />
        </h1>
        {favoriteError && <p className="error-message">{favoriteError}</p>}

        {userIsHighestBidder && timeRemaining !== t('auctionEnded') && (
          <p className="notification">{t('youAreCurrentlyTheHighestBidder')}</p>
        )}

        {isAuctionOver && userWonAuction && delivery ? (
          <div className="delivery-details">
            <h2>{t('youWonTheAuction')}</h2>
            <h3>{t('deliveryDetails')}</h3>
            <p><strong>{t('deliveryDate')}:</strong> {new Date(delivery.date).toLocaleDateString()}</p>
            <p><strong>{t('shippingType')}:</strong> {t(`${delivery.shippingAddress.type}`)}</p>
            <p><strong>{t('address')}:</strong> 
              {delivery.shippingAddress.addressName}, {delivery.shippingAddress.address1}
              {delivery.shippingAddress.address2 ? `, ${delivery.shippingAddress.address2}` : ''}
              {delivery.shippingAddress.address3 ? `, ${delivery.shippingAddress.address3}` : ''}
              {delivery.shippingAddress.postalCode}, {delivery.shippingAddress.city}, {delivery.shippingAddress.country}
            </p>
            <p><strong>{t('phoneNumber')}:</strong> {delivery.shippingAddress.phoneNumber}</p>
            <p><strong>{t('status')}:</strong> {t(`${delivery.status}`)}</p>
          </div>
        ) : isAuctionOver ? (
          <p className="auction-ended-notification">{t('auctionEnded')}</p>
        ) : (
          <>
            <div className="product-price">
              <span className="label">{t('currentHighestBid')}:</span>
              <span className="amount">{product.bid ? `${product.bid.amount}` : `${product.startingPrice}`}</span>
              <span className="currency">€</span>
            </div>
            <p className="product-description">{t('description')}: {product.description}</p>
            <p className="product-model">
              {t('model')}: 
              <Link to={`/viewpartner/${product.model.userId}`} className="model-link">
                {product.model.username}
              </Link>
            </p>
            <p className="product-category">{t('category')}: {product.category}</p>
            <p className="auction-timer" style={{ animationDuration: pulseSpeed }}>{t('timeRemaining')}: {timeRemaining}</p>

            <div className="bid-section">
              {isAuthenticated ? (
                user.shippingAddress ? (
                  user.isVerified ? (
                    <>
                      <input
                        type="number"
                        value={userBid}
                        onChange={handleBidChange}
                        placeholder={t('placeYourBid')}
                        step="1"
                        min="5"
                      />
                      {shippingFees && userBid < SHIPPING_FEES_THRESHOLD && (
                        <>
                        <p className="shipping-fees">{t('shippingFees')}: {shippingFees}€</p>
                        <p className="shipping-fees">({t('shippingFeesDetails')} : {SHIPPING_FEES_THRESHOLD} €)</p>
                        </>
                      )}
                      <button className="bid-button" onClick={handleBidSubmit}>{t('submitBid')}</button>
                      {biddingError && <p className="error-message">{biddingError}</p>}
                    </>
                  ) : (
                    <p className="error-message">
                      {t('needToVerifyEmail')}{' '}
                      <button className="settings-nav-button" onClick={() => navigate('/settings')}>{t('verifyEmail')}</button>
                    </p>
                  )
                ) : (
                  <p className="error-message">
                    {t('needToSetShippingAddress')}{' '}
                    <button className="settings-nav-button" onClick={() => navigate('/settings')}>{t('setShippingAddress')}</button>
                  </p>
                )
              ) : (
                <button className="login-nav-button" onClick={() => navigate('/login')}>{t('loginToPlaceBid')}</button>
              )}
            </div>
          </>
        )}
      </div>

      {/* Confirmation Modal */}
      <Modal
        isOpen={isConfirmModalOpen && user?.shippingAddress}  // Ensure modal only opens if shippingAddress is defined
        onRequestClose={() => setIsConfirmModalOpen(false)}
        contentLabel={t('confirmBid')}
        className="modal"
        overlayClassName="overlay"
      >
        <h2>{t('confirmBid')}</h2>
        <p className='modal-message'>
          {userBid < SHIPPING_FEES_THRESHOLD
            ? t('confirmBidMessageWithShippingFees', { bidAmount: userBid, shippingFees: shippingFees })
            : t('confirmBidMessage', { bidAmount: userBid })}
        </p>
        <p className='modal-message'>{t('agreementMessage')}</p>
        <p className='modal-message'>{t('confirmAddressMessage')}</p>
        
        {user?.shippingAddress && (
          <p className="shipping-address">
            {`${user.shippingAddress.addressName} (${
              user.shippingAddress.type === "Relay-Point"
                ? user.shippingAddress.relayPointName
                : user.shippingAddress.address1
            })`}
          </p>
        )}

        <div className="modal-buttons">
          <button className="modal-button confirm" onClick={handleConfirmBid}>
            {t('confirm')}
          </button>
          <button className="modal-button cancel" onClick={() => setIsConfirmModalOpen(false)}>
            {t('cancel')}
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={isSuccessModalOpen}
        onRequestClose={() => setIsSuccessModalOpen(false)}
        contentLabel={t('bidSuccess')}
        className="modal"
        overlayClassName="overlay"
      >
        <h2>{t('bidSuccess')}</h2>
        <p>{t('bidPlacedSuccessfully')}</p>
        <div className="modal-buttons">
          <button className='modal-button confirm' onClick={() => setIsSuccessModalOpen(false)}>{t('ok')}</button>
        </div>
      </Modal>
    </div>
  );
};

export default ViewProduct;
