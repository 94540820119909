import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import './Settings.css';
import { AuthContext } from '../../context/AuthContext';
import { API_BASE_URL } from '../../config/start.mjs';
import { useTranslation } from 'react-i18next';
import ColissimoWidget from '../../components/ColissimoWidget/ColissimoWidget';
import ShippingAddressForm from "../../components/ShippingAddressForm.js/ShippingAddressForm"
import { useNavigate } from 'react-router-dom';

const Settings = () => {
  const { user, setUser, setIsAuthenticated } = useContext(AuthContext);
  const { username, email } = user; // Destructure username and email from user object
  const { t } = useTranslation();
  const navigate= useNavigate()

  const initialShippingAddress = {
    type: "",
    addressName:t("residence"),
    address1: "",
    address2: "", 
    address3: "", 
    postalCode: "",
    city: "",
    country: "",
    lastName: "", 
    firstName: ""
  };

  const [settings, setSettings] = useState(user.settings);
  const [newUsername, setNewUsername] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showUsernameModal, setShowUsernameModal] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(initialShippingAddress);
  const [useRelayPoint, setUseRelayPoint] = useState(false)
  const [isAddressesDropdownOpen, setIsAddressesDropdownOpen] = useState(false);
  const [hasSelectedRelayPoint, setHasSelectedRelayPoint] = useState(false);

  const dropdownRef = useRef(null);

  const [addressError, setAddressError] = useState('');


  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/user/settings`);
        setSettings(response.data);
      } catch (error) {
        console.error('Error fetching user settings', error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    if (showAddressModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showAddressModal]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsAddressesDropdownOpen(false);
      }
    };

    if (isAddressesDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isAddressesDropdownOpen]);

  const handleSettingChange = async (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;

    const updatedSettings = {
      ...settings,
      [name]: newValue,
    };
    setSettings(updatedSettings);

    try {
      await axios.put(`${API_BASE_URL}/api/user/settings`, updatedSettings);
    } catch (error) {
      console.error(`Error updating ${name} setting`, error);
    }
  };

  const handleChangePassword = async () => {
    try {
      await axios.post(`${API_BASE_URL}/api/user/resetPassword`, { email });
      alert(t('passwordResetLinkSent'));
    } catch (error) {
      console.error('Error resetting password', error);
      alert(t('errorResettingPassword'));
    }
    setShowPasswordModal(false);
  };

  const handleChangeEmail = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/api/user/updateEmail`, { email: newEmail });
      setUser((prevUser) => ({
        ...prevUser,
        email: response.data.email,
        isVerified:response.data.isVerified
      }));
      alert(t('emailUpdatedSuccessfully'));
    } catch (error) {
      console.error('Error updating email', error);
      alert(t('errorUpdatingEmail'));
    }
    setShowEmailModal(false);
  };

  const handleSendVerificationEmail = async () => {
    try {
      await axios.get(`${API_BASE_URL}/api/user/${user._id}/sendVerificationEmail`);
      alert(t('verificationEmailSent'));
    } catch (error) {
      console.error('Error sending verification email', error);
      alert(t('errorSendingVerificationEmail'));
    }
  };

  const handleChangeUsername = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/api/user/updateUsername`, { username: newUsername });
      setUser((prevUser) => ({
        ...prevUser,
        username: response.data.username
      }));
      alert(t('usernameUpdatedSuccessfully'));
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // The username is already in use
        alert(t('usernameAlreadyInUse'));
      } else {
        console.error('Error updating username', error);
        alert(t('errorUpdatingUsername'));
      }
    }
    setShowUsernameModal(false);
  };

  const isAddressNameTaken = (name) => {
    return user.savedShippingAddresses.some(address => address.addressName === name);
  };

  const handleAddressNameChange = (e) => {
    const newAddressName = e.target.value;
    setShippingAddress({ ...shippingAddress, addressName: newAddressName });

    if (isAddressNameTaken(newAddressName)) {
      setAddressError(t('addressNameTaken')); // Display error message if address name is taken
    } else {
      setAddressError('');
    }
  };

  const handleAddressSelected =(point) => {
    const shippingAddress = {
      type: "Relay-Point",
      addressName:t("relayPoint"),
      address1: point.adresse1,
      address2: point.adresse2 || '', 
      address3: point.adresse3 || '', 
      postalCode: point.codePostal,
      city: point.localite,
      country: point.libellePays,
      relayPointName: point.nom, 
      identifier: point.identifiant 
    };
    setShippingAddress(shippingAddress);
    if(isAddressNameTaken(t('relayPoint'))){
      setAddressError(t('addressNameTaken'))
    }
    console.log("SHIPPING ADDRESS MODIFIED")
    setHasSelectedRelayPoint(true);
  };

  const handleShippingAddressSubmit = async  (e) => {
    e.preventDefault();
    shippingAddress.type="Direct"
    setShowAddressModal(false)
    await sendNewAddress(shippingAddress)
  }

  const sendNewAddress = async (shippingAddress) => {
    try {
      // Send the shipping address to the backend server
      const response = await axios.post(`${API_BASE_URL}/api/user/${user._id}/shipping-address`, { shippingAddress });
      
      if (response.status === 200) {
        console.log('Address successfully saved and set as current shipping address.');
        setUser((prevUser) => ({
          ...prevUser,
          shippingAddress: shippingAddress,
          savedShippingAddresses:response.data.savedShippingAddresses
        }))
      } else {
        alert(t('errorSubmittingNewAddress'))
        console.error('Failed to save and set the shipping address.');
      }
    } catch (error) {
      console.error('Error sending new address:', error);
    }
  };

  const toggleDropdown = () => {
    setIsAddressesDropdownOpen(!isAddressesDropdownOpen);
  }
  
  const handleConfirmRelayPoint = async ()=> {
    console.log("NEW ADRESS SENT")
    await sendNewAddress(shippingAddress)
    setShowAddressModal(false)
    setHasSelectedRelayPoint(false)
  }

  const handleAdressSelectedInDropdown = async(index) => {
    try {

      const response = await axios.post(`${API_BASE_URL}/api/user/${user._id}/addresses`, {index});

    if (response.status === 200) {  
      setUser((prevUser) => ({
        ...prevUser,
        shippingAddress: response.data.shippingAddress,
      }))
    } else {
      // Handle non-200 response
      console.error('Failed to change the selected address:', response);
    }
    setIsAddressesDropdownOpen(false)
  } catch (error) {
    console.error('Error switching address:', error);
  }
  }

  const handleDeleteAddress = async (index) => {
    try {

        const response = await axios.delete(`${API_BASE_URL}/api/user/${user._id}/addresses`, {
        data: { index } 
      });
  
      if (response.status === 200) {  
        setUser((prevUser) => ({
          ...prevUser,
          shippingAddress: response.data.shippingAddress,
          savedShippingAddresses:response.data.savedShippingAddresses
        }))
      } else {
        // Handle non-200 response
        console.error('Failed to delete the address:', response);
      }
    } catch (error) {
      console.error('Error deleting address:', error);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await axios.delete(`${API_BASE_URL}/api/user/${user._id}`);
      alert(t('accountDeleted'));
      setUser(null)
      setIsAuthenticated(false)
      navigate('/login')
    } catch (error) {
      console.error('Error deleting account', error);
      alert(t('errorDeletingAccount'));
    }
    setShowDeleteAccountModal(false);
  };

  const closeModal = () => {
    setShowPasswordModal(false);
    setShowEmailModal(false);
    setShowUsernameModal(false);
    setShowAddressModal(false);
    setShowDeleteAccountModal(false)
  };

  return (
    <div className='background'>
      <div className="settings-container">

        {/* My Account section displaying current username and email */}
        <div className="my-account-section">
          <h1>{t('myAccount')}</h1>
          <div className="user-info">
            <p><strong>{t('currentUsername')}:</strong> {username}</p>
            <p>
              <strong>{t('currentEmail')}:</strong> {email}
              {!user.isVerified && (
                <div style={{marginLeft:'10px'}}>
                  <button 
                    className="verify-email-button" 
                    onClick={handleSendVerificationEmail}
                  >
                    {t('verifyEmail')}
                  </button>
                </div>
              )}
            </p>
          </div>
        </div>

        <h1>{t('settings')}</h1>
  
        {/* Preferences section */}
        <div className="settings-section">
          <h2>{t('preferences')}</h2>
          <label className='checkbox'>
            <input
              type="checkbox"
              name="notifications"
              checked={settings.notifications}
              onChange={handleSettingChange}
            />
            <span className='checkmark'></span>
            {t('receiveNotifications')}
          </label>
          <label className='checkbox'> 
            <input
              type="checkbox"
              name="emailUpdates"
              checked={settings.emailUpdates}
              onChange={handleSettingChange}
            />
            <span className='checkmark'></span>
            {t('receiveEmailUpdates')}
          </label>
        </div>
  
        {/* Account settings section */}
        <div className="settings-section">
          <h2>{t('account')}</h2>
          <div className="settings-buttons-container">
            <button className="settings-button" onClick={() => setShowUsernameModal(true)}>
              {t('changeUsername')}
            </button>
            <button className="settings-button" onClick={() => setShowEmailModal(true)}>
              {t('changeEmail')}
            </button>
            <button className="settings-button" onClick={() => setShowPasswordModal(true)}>
              {t('changePassword')}
            </button>
          </div>
        </div>

        <div className="settings-section">
          <h2>{t('shippingAddress')}</h2>
          
          {shippingAddress && (
          <div className="address-display">
            <div>
              <p onClick={toggleDropdown} className="selected-address">
                {t('selectedAddress')} :  
                {user.shippingAddress ? `${user.shippingAddress.addressName} (
                ${user.shippingAddress.type==="Relay-Point"? user.shippingAddress.relayPointName: user.shippingAddress.address1}
                )`  : t("noAddressSelected")}
              </p>

              {isAddressesDropdownOpen && (
              <div className="address-dropdown" ref={dropdownRef}>
                <ul>
                  {user.savedShippingAddresses && user.savedShippingAddresses.length > 0 ? (
                    user.savedShippingAddresses.map((address, index) => (
                      <li key={index} className="address-item">
                        <span onClick={() => {handleAdressSelectedInDropdown(index)}} 
                        className='address-name'>
                          {address.addressName}  ({address.type==="Relay-Point"? address.relayPointName : address.address1})
                        </span>
                        <button
                          className="delete-address"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteAddress(index);
                          }}
                        >
                          &times;
                        </button>
                      </li>
                    ))
                  ) : (
                    <li className="no-addresses">{t('noSavedAddresses')}</li>
                  )}
                </ul>
              </div>
            )}
            </div>
          </div>
        )}
          <button className="settings-button" onClick={() =>{
            if(isAddressNameTaken(shippingAddress.addressName)){
              setAddressError(t('addressNameTaken'))
            }
            setShowAddressModal(true)
            }}>
            {t('setNewAddress')}
          </button>

          {user.role==='user' && (
            <button className="settings-button delete-account-button" onClick={() => setShowDeleteAccountModal(true)}>
              {t('deleteAccount')}
            </button>
          )}
        </div>
  
        {/* Modals */}
        {showPasswordModal && (
          <div className="modal-overlay-settings" onClick={closeModal}>
            <div className="modal-content-settings" onClick={(e) => e.stopPropagation()}>
              <h2>{t('changePassword')}</h2>
              <p>{t('passwordResetInstructions')}</p>
              <button className="modal-button-settings primary-button" onClick={handleChangePassword}>
                {t('sendPasswordResetLink')}
              </button>
              <button className="modal-button-settings close-button" onClick={closeModal}>
                {t('close')}
              </button>
            </div>
          </div>
        )}
  
        {showEmailModal && (
          <div className="modal-overlay-settings" onClick={closeModal}>
            <div className="modal-content-settings" onClick={(e) => e.stopPropagation()}>
              <h2>{t('changeEmail')}</h2>
              <input
                type="email"
                className="modal-input-settings"
                placeholder={t('enterNewEmail')}
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
              />
              <button className="modal-button-settings primary-button" onClick={handleChangeEmail}>
                {t('updateEmail')}
              </button>
              <button className="modal-button-settings close-button" onClick={closeModal}>
                {t('close')}
              </button>
            </div>
          </div>
        )}
  
        {showUsernameModal && (
          <div className="modal-overlay-settings" onClick={closeModal}>
            <div className="modal-content-settings" onClick={(e) => e.stopPropagation()}>
              <h2>{t('changeUsername')}</h2>
              <input
                type="text"
                className="modal-input-settings"
                placeholder={t('enterNewUsername')}
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
              />
              <button className="modal-button-settings primary-button" onClick={handleChangeUsername}>
                {t('updateUsername')}
              </button>
              <button className="modal-button-settings close-button" onClick={closeModal}>
                {t('close')}
              </button>
            </div>
          </div>
        )}

        {showAddressModal && (
          <div className="modal-overlay-settings address-modal" onClick={closeModal}>
            <div className="modal-content-settings address-modal" onClick={(e) => e.stopPropagation()}>
              <h2>{useRelayPoint ? t('selectDeliveryPoint') : t('FillInYourAddress')}</h2>

              {/* Centered Checkbox to toggle between Relay Point and Manual Address */}
              <div className="checkbox-container">
                <label className="relay-point-checkbox">
                  <input
                    type="checkbox"
                    checked={useRelayPoint}
                    onChange={() => setUseRelayPoint(!useRelayPoint)}
                  />
                  <span className="checkmark"></span>
                  {t('useRelayPoint')}
                </label>
              </div>

              {/* Conditional rendering based on checkbox selection and whether a relay point is selected */}
              {useRelayPoint ? (
                hasSelectedRelayPoint ? (
                  <div className="relay-point-container">
                  {shippingAddress.relayPointName && (
                    <>
                      {/* Display selected relay point */}
                      <div className="selected-relay-point">
                        <p className="relay-point-title">{t('selectedRelayPoint')}</p>
                        <p className="relay-point-name">{shippingAddress.relayPointName}</p>
                        <button
                        className="relay-point-change-button"
                        onClick={() => setHasSelectedRelayPoint(false)}
                      >
                        {t('change')}
                      </button>
                      </div>

                      {/* Form for address name and last name */}
                      <form className="relay-point-form" onSubmit={async (e) => {
                        e.preventDefault();
                        if (addressError) return; // Prevent default form submission

                        // Basic phone number validation
                        const phoneNumberPattern = /^\+?[0-9]{1,15}$/; // Allows an optional '+' followed by 1-15 digits
                        if (!phoneNumberPattern.test(shippingAddress.phoneNumber)) {
                          setAddressError(t('invalidPhoneNumber')); // Set an error message if the phone number is invalid
                          return;
                        }

                        await handleConfirmRelayPoint(); // Handle form submission
                      }}>
                        <div>
                          <label htmlFor="addressName">{t('addressName')}</label>
                          <input
                            id="addressName"
                            type="text"
                            placeholder={t('addressName')}
                            value={shippingAddress.addressName || ''}
                            onChange={handleAddressNameChange}
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="lastName">{t('name')}</label>
                          <input
                            id="lastName"
                            type="text"
                            placeholder={t('name')}
                            value={shippingAddress.lastName || ''}
                            onChange={(e) => setShippingAddress({ ...shippingAddress, lastName: e.target.value })}
                            required
                          />
                          {addressError && <p className="error-message">{addressError}</p>}
                        </div>
                        <div>
                          <label htmlFor="firstName">{t('firstName')}</label>
                          <input
                            id="firstName"
                            type="text"
                            placeholder={t('firstName')}
                            value={shippingAddress.firstName || ''}
                            onChange={(e) => setShippingAddress({ ...shippingAddress, firstName: e.target.value })}
                            required
                          />
                          {addressError && <p className="error-message">{addressError}</p>}
                        </div>
                        <div>
                          <label htmlFor="phoneNumber">{t('phoneNumber')}</label>
                          <input
                            id="phoneNumber"
                            type="tel"
                            className={`phone-number-input ${addressError ? 'error' : ''}`} // 'tel' input type for phone numbers
                            placeholder={t('phoneNumber')}
                            value={shippingAddress.phoneNumber || ''}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value.length <= 15) { // Limit to 15 characters
                                setShippingAddress({ ...shippingAddress, phoneNumber: value });
                              }
                            }}
                            required
                          />
                          {addressError && <p className="error-message">{addressError}</p>}
                        </div>
                        <button
                          type="submit"
                          className="modal-button-settings address-modal-button"
                          style={{ marginTop: '0px' }}
                        >
                          {t('confirm')}
                        </button>
                      </form>
                    </>
                  )}
                </div>
                ) : (
                  <ColissimoWidget onAddressSelected={handleAddressSelected} />
                )
              ) : (
                <ShippingAddressForm
                  shippingAddress={shippingAddress}
                  setShippingAddress={setShippingAddress}
                  handleShippingAddressSubmit={handleShippingAddressSubmit}
                  handleAddressNameChange= {handleAddressNameChange}
                  addressError={addressError}
                  t={t}
                />
              )}

              {/* Close button in case relay point is not selected */}
              {!hasSelectedRelayPoint && (
                <button
                  className="modal-button-settings address-modal-button"
                  onClick={closeModal}
                  style={{ marginTop: useRelayPoint ? '200px' : '45px' }}
                >
                  {t('close')}
                </button>
              )}
            </div>
          </div>
        )}
        {showDeleteAccountModal && (
          <div className="modal-overlay-settings" onClick={closeModal}>
            <div className="modal-content-settings" onClick={(e) => e.stopPropagation()}>
              <h2>{t('confirmDeleteAccount')}</h2>
              <p>{t('deleteAccountWarning')}</p>
              <button className="modal-button-settings primary-button" onClick={handleDeleteAccount}>
                {t('deleteAccount')}
              </button>
              <button className="modal-button-settings close-button" onClick={closeModal}>
                {t('cancel')}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
