import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductList from '../../components/ProductList/ProductList';
import axios from 'axios';
import { API_BASE_URL } from '../../config/start.mjs';
import { AuthContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import './Home.css';

const Home = () => { 
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [favoriteProducts, setFavoriteProducts] = useState([]);
  const { isAuthenticated, user } = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/products`);
        const data = response.data;
        setProducts(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false); // Ensure loading state is set to false after fetching
      }
    };

    const fetchFavoriteProducts = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/user/${user._id}/favorites`);
        setFavoriteProducts(response.data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchProducts();
    if (isAuthenticated) {
      fetchFavoriteProducts();
    }
  }, [isAuthenticated, user]);

  const handleProductClick = (product) => {
    navigate(`/viewproduct/${product._id}`);
  };

  if (loading) {
    return <div>{t("loading")}</div>;
  }

  if (error) {
    return <div>{t("error")}{error}</div>;
  }

  return (
    <div className='background'>
      <h1>{t('home')}</h1>
      {isAuthenticated && (
        <>
          <h2 className='page-heading'>{t('favoriteProducts')}</h2>
          {favoriteProducts.length > 0 ? (
            <ProductList products={favoriteProducts} handleProductClick={handleProductClick} />
          ) : (
            <p>{t('noFavoriteProducts')}</p>
          )}
        </>
      )}
      
      <h2 className='page-heading'>{t('mostRecentProducts')}</h2>
      {products.length > 0 ? (
        <ProductList products={products} handleProductClick={handleProductClick} />
      ) : (
        <p>{t('noProductsAvailable')}</p>  // Display message when no products are available
      )}
    </div>
  );
};

export default Home;
